@import "assets/styles/init";

@keyframes drawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 1000;

  &.isOpened {
    width: 100%;

    .drawerMask {
      height: 100%;
      opacity: 1;
      transition: none;
      animation: drawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
      pointer-events: auto;
    }

    .drawerContent {
      transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
      transform: translateX(0);
    }
  }

  &Mask {
    @include absolute(0, auto, auto, 0);
    width: 100%;
    height: 0;
    background-color: rgba(31, 6, 92, 0.9);
    opacity: 0;
    filter: alpha(opacity=45);
    transition: opacity 0.3s linear, height 0s ease 0.3s;
    pointer-events: none;
  }

  &Content {
    @include absolute(auto, 0, auto, auto);
    height: 100%;
    width: 1006px;
    transform: translateX(100%);
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

    @include tablets {
      width: 100vw;
    }
  }

  &Buttons {
    @include absolute(auto, 0, auto, auto);

    @include phones {
      @include absolute(-30px, 0, auto, auto);
    }

    &Close {
      @include font(500, 18px, 24px);
      color: $default-text;
      display: flex;
      align-items: center;
      cursor: pointer;

      @include phones {
        @include font(600, 16px, 37px);
      }

      &Icon {
        width: 24px;
        height: 24px;
        margin-right: $gap1;
        cursor: pointer;
      }

      &Text {
        @include phones {
          display: none;
        }
      }

      &:hover {
        @include transition();
        color: $active-text-btn;
      }
    }
  }

  &WrapperBody {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: auto;
    background: $white;
    background-clip: padding-box;
    border: 0;
    padding: $gap6;

    @include tablets {
      padding: $gap4 $gap2;
    }

    @include phones {
      padding: $gap5 0 $gap4;
    }
  }

  &Body {
    position: relative;
    width: 100%;
    display: flex;
  }
}
