@import "../assets/styles/reset/reset";
@import "../assets/styles/init";
@import "assets/styles/sprites/sprite-svg";

html {
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;
  font-family: $system-fonts-list;
}

* {
  box-sizing: border-box;
}

body {
  @extend %custom-scrollbar;

  font: 400 #{$fs-base}/#{$lh-base} $regular-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  overflow-x: hidden;
  overflow-y: auto;
  color: $default-text;
  font-weight: normal;
  line-height: 1;
  width: 100vw;

  @include phones {
    @include font(400, 11px, 14px);
  }

  #__next {
    width: 100%;
  }

  &.isMobMenuOpen {
    overflow: hidden;
  }
}

button {
  margin: 0;
  border: 0;
  background: transparent;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: 0;
  font-family: $regular-font;
  &::-moz-focus-inner {
    border: 0;
  }
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

h1 {
  @include font(bold, 86px, 84px);
}

h2 {
  @include font(bold, 46px, 64px);

  @include phones-landscape {
    @include font(bold, 28px, 64px);
  }
}

h3 {
  @include font(bold, 34px, 36px);

  @include phones-landscape {
    @include font(bold, 24px, 36px);
  }
}

ul,
div,
section,
textarea {
  @extend %custom-scrollbar;
}

.react-select__placeholder {
  font-size: 20px;

  @include phones {
    font-size: 18px;
  }
}
